// 引用axios
import axios from 'axios'
// export let root ='http://47.243.77.234:32194/v1/ssfr';
// export let root ='https://openapigcp.sanguosha.cn/ssfr';
export let root ='/api/ssfr';
// let root ='https://seasjforum.sanguosha.cn';
function toType (obj){
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull (opions){
  for (var key in opions) {
    if (opions[key] === null) {
      delete opions[key]
    }
    if (toType(opions[key]) === 'string') {
      opions[key] = opions[key].trim()
    } else if (toType(opions[key]) === 'object') {
      opions[key] = filterNull(opions[key])
    } else if (toType(opions[key]) === 'array') {
      opions[key] = filterNull(opions[key])
    }
  }
  return opions
}
/*
  接口处理函数
*/

function apiAxios (method, url, params, success, failure,){
  if (params) {
    params = filterNull(params)
  }
  axios.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer '+ localStorage.getItem('token');
    return config
  }, error => {
    console.log(method, url, params, success, failure);
    return Promise.reject(error)
  });
  axios({
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    baseURL: root,
    withCredentials: false,
    contentType: false,
  })
    .then(function (res) {
      if (res.status === 200) {
        if (res.data.code == 0) {
          success(res.data)
        } else {
          success(res.data)
        }
      } else {

        if (failure) {
          failure(res.data)
        } else {
          if (res.data.code ===401) {
            localStorage.removeItem('token');
            console.log('error: ' + JSON.stringify(res.data.msg))
          }
          console.log('error: ' + JSON.stringify(rIs-Refresh-Tokenes.data))
        }
      }
    })
    .catch(function (err) {
      let res = err.response||{};
      if (err) {
        
        console.log('api error, HTTP CODE: ' + res.status);
        return
      }
    })
}
// 返回在vue模板中的调用接口
export default {
  get: function (url, params, success, failure) {
    return apiAxios('GET', url, params, success, failure)
  },
  post: function (url, params, success, failure) {
    return apiAxios('POST', url, params, success, failure)
  },
  upload: function (url, params, success, failure,contentType) {
    return apiAxios('POST', url, params, success, failure,contentType)
  }
}
