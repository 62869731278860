import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
export const myVue = Vue;

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/postIndex',
    meta: {
      keepAlive: true,
    },
    // component: () =>

    //   import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ticket/ticket.vue'),
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/strategy/strategy.vue'),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/detail/detail.vue'),
  },
  {
    path: '/mall',
    name: 'mall',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/mall/mall.vue'),
  },
  {
    path: '/heroDetail',
    name: 'heroDetail',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/heroDetail/heroDetail.vue'
      ),
  },
  {
    path: '/postIndex',
    name: 'postIndex',
    component: () => import('../views/postIndex/postIndex.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/addPost',
    name: 'addPost',
    component: () => import('../views/addPost/AddPost.vue'),
  },
  {
    path: '/postDetail',
    name: 'postDetail',
    component: () => import('../views/postDetail/PostDetail.vue'),
  },
  {
    path: '/commentReply',
    name: 'commentReply',
    component: () => import('../views/commentReply/CommentReply.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine/Mine.vue'),
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/exchange/Exchange.vue'),
  },
  {
    path: '/fans',
    name: 'fans',
    component: () => import('../views/fans/Fans.vue'),
  },
  {
    path: '/focus',
    name: 'focus',
    component: () => import('../views/focus/Focus.vue'),
  },
  {
    path: '/author',
    name: 'author',
    component: () => import('../views/author/Author.vue'),
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message/Message.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
