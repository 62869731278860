
<template>
  <div id="app">
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          v-if="$route.meta.keepAlive"
          key="/postIndex"
        />
      </keep-alive>
      <component
        :is="Component"
        v-if="!$route.meta.keepAlive"
      />
    </router-view> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!--不需要缓存的keep-alive 配置 展示内容-->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>


<script>
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isReady: true,
      token: localStorage.getItem('token') || '',
      isRouterAlive: true,
      pgt: window.location.href.split('?pgt=')[1],
      oldPgt: localStorage.getItem('pgt') || '',
      expire: localStorage.getItem('expire') || '' //过期时间
    }
  },
  created() {
    console.log(window.location.hash)
    // 第三方登录回调处理异常hash
    if (window.location.hash == '#/_=_') {
      const url = location.href
      const paramObj = this.queryURLParams(url)
      if (JSON.stringify(paramObj) != '{}') {
        this.$router.push({
          path: 'postIndex',
          query: {
            code: paramObj.code,
            state: paramObj.state
          }
        })
      }
    }

    // this.pgt='lG7XZd+D5i2LBA+mTxBZgia8U2CikWSgvmFJzo1jV0tL4OcsvVZHnG+9SZg='
    // localStorage.token =
    //   'lG7XZd+D5i2LBA+mTxBZgia8U2CikWSgvmFJzo1jV0tL4OcsvVZHnG+9SZg='
    // console.log(111, localStorage.token)
    const timeOver = 6 * 24 * 3600 * 1000
    let date = Math.round(new Date().getTime() / 1000)
    if (this.pgt) {
      this.pgt = decodeURIComponent(this.pgt).split('#')[0]
      // this.pgt = 'lG7XZd+D5i2LBA+mTxBZgia8U2CikWSgvmFJzo1jV0tL4OcsvVZHnG+9SZg='
      if (
        this.pgt !== this.oldPgt ||
        this.token == '' ||
        date - this.expire > timeOver
      ) {
        this.pgtLogin()
      }
    }
  },
  methods: {
    queryURLParams(URL) {
      //   let pattern = /(\w+)=(\w+)/gi
      //   let parames = {}
      //   url.replace(pattern, ($, $1, $2) => {
      //     parames[$1] = $2
      //   })
      //   return parames
      let base = URL.split('#')[0]
      let url = base.split('?')[1]
      let obj = {} // 声明参数对象
      let arr = url.split('&') // 以&符号分割为数组
      for (let i = 0; i < arr.length; i++) {
        let arrNew = arr[i].split('=') // 以"="分割为数组
        obj[arrNew[0]] = arrNew[1]
      }
      return obj
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
        location.reload()
      })
    },
    pgtLogin() {
      this.$http.get(
        '/auth/login/pgt?pgt=' + encodeURIComponent(this.pgt),
        {},
        (res) => {
          if (res.code == 0) {
            console.log(res.data.token)
            this.token = res.data.token
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('pgt', decodeURIComponent(this.pgt))
            localStorage.setItem('expire', res.data.expire)
            this.reload()
          } else {
            this.$toast.center(res.msg)
          }
        }
      )
    }
  }
}
</script>
<style lang="scss">
@import './style/common.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: 'Microsoft YaHei', Helvetica, Arial, Microsoft Yahei, sans-serif;
}

a,
area {
  display: inline-block;
  outline: 0;
  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

img {
  border: 0;
  vertical-align: middle;
  display: block;
}

input,
button {
  cursor: pointer;
  -webkit-appearance: none !important;
  outline: none;
  border: none;
}

body {
  // background: #b44648;
  background: url(../src/assets/bg3_02.jpg) 0 0;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.icon {
  background: url(../src/assets/icon.png) 0 0 no-repeat;
  background-size: 8.32rem auto;
}

.space-btween {
  justify-content: space-between;
}

.fade {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.lx-toast {
  position: fixed;
  bottom: 1.33rem;
  left: 50%;
  box-sizing: border-box;
  width: auto;
  max-width: 90%;
  height: 1.07rem;
  line-height: 1.07rem;
  padding: 0 0.27rem;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  text-align: center;
  z-index: 9999;
  font-size: 0.37rem;
  color: #fff;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  animation: show-toast 0.8s;
  -webkit-animation: show-toast 0.8s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lx-toast.lx-word-wrap {
  width: 80%;
  white-space: inherit;
  height: auto;
}

.lx-toast.lx-toast-top {
  top: 0.67rem;
  bottom: inherit;
}

.lx-toast.lx-toast-center {
  top: 50%;
  margin-top: -20px;
  bottom: inherit;
}

.lx-toast.lx-toast-auto {
  height: auto;
  line-height: 45px;
  width: 50%;
  top: 50%;
  margin-top: -0.27rem;
  bottom: inherit;
  overflow: inherit;
  white-space: inherit;
  padding: 0.27rem;
}

@keyframes show-toast {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
    -webkit-transform: translate(-50%, -10px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
  }
}

@-webkit-keyframes opacity-0 {
  0% {
    opacity: 0.25;
  }

  0.01% {
    opacity: 0.25;
  }

  0.02% {
    opacity: 1;
  }

  60.01% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes opacity-1 {
  0% {
    opacity: 0.25;
  }

  8.34333% {
    opacity: 0.25;
  }

  8.35333% {
    opacity: 1;
  }

  68.3433% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes opacity-2 {
  0% {
    opacity: 0.25;
  }

  16.6767% {
    opacity: 0.25;
  }

  16.6867% {
    opacity: 1;
  }

  76.6767% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes opacity-3 {
  0% {
    opacity: 0.25;
  }

  25.01% {
    opacity: 0.25;
  }

  25.02% {
    opacity: 1;
  }

  85.01% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes opacity-4 {
  0% {
    opacity: 0.25;
  }

  33.3433% {
    opacity: 0.25;
  }

  33.3533% {
    opacity: 1;
  }

  93.3433% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes opacity-5 {
  0% {
    opacity: 0.270958333333333;
  }

  41.6767% {
    opacity: 0.25;
  }

  41.6867% {
    opacity: 1;
  }

  1.67667% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.270958333333333;
  }
}

@-webkit-keyframes opacity-6 {
  0% {
    opacity: 0.375125;
  }

  50.01% {
    opacity: 0.25;
  }

  50.02% {
    opacity: 1;
  }

  10.01% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.375125;
  }
}

@-webkit-keyframes opacity-7 {
  0% {
    opacity: 0.479291666666667;
  }

  58.3433% {
    opacity: 0.25;
  }

  58.3533% {
    opacity: 1;
  }

  18.3433% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.479291666666667;
  }
}

@-webkit-keyframes opacity-8 {
  0% {
    opacity: 0.583458333333333;
  }

  66.6767% {
    opacity: 0.25;
  }

  66.6867% {
    opacity: 1;
  }

  26.6767% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.583458333333333;
  }
}

@-webkit-keyframes opacity-9 {
  0% {
    opacity: 0.687625;
  }

  75.01% {
    opacity: 0.25;
  }

  75.02% {
    opacity: 1;
  }

  35.01% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.687625;
  }
}

@-webkit-keyframes opacity-10 {
  0% {
    opacity: 0.791791666666667;
  }

  83.3433% {
    opacity: 0.25;
  }

  83.3533% {
    opacity: 1;
  }

  43.3433% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.791791666666667;
  }
}

@-webkit-keyframes opacity-11 {
  0% {
    opacity: 0.895958333333333;
  }

  91.6767% {
    opacity: 0.25;
  }

  91.6867% {
    opacity: 1;
  }

  51.6767% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.895958333333333;
  }
}

#loading {
  width: 1.3rem;
  height: 0.97rem;
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 10px;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 40%;
  margin-left: -25px;
  margin-top: 30px;
}

#loading>div {
  background-color: #c85655;
  height: 100%;
  width: 0.15rem;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

#loading .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

#loading .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

#loading .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

#loading .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>